.navigation {
  background-color: rgba(var(--body-background-rgb), .75);
  backdrop-filter: blur(.25rem);
  position: sticky;
  top: 0;
  z-index: 2;
}

.navigation__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $container;

  @media screen and (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.navigation__items-container {
  align-self: stretch;
  display: flex;
  position: relative;
  order: 2;

  @media screen and (min-width: $breakpoint) {
    flex-grow: 1;
    order: 1;
  }
}

.navigation__brand {
  color: var(--color-blue);
  font-size: 1.25rem;
  letter-spacing: .125rem;
  margin-right: auto;
  padding: 1rem 0 1rem .5rem;
  text-decoration: none;
}

.navigation__items-toggle {
  background-color: transparent;
  border: 0;
  font-size: 1.5rem;
  padding: .25rem .5rem;

  @media screen and (min-width: $breakpoint) {
    display: none;
  }
}

.navigation__items {
  background-color: rgba(var(--body-background-rgb), .75);
  backdrop-filter: blur(.25rem);
  list-style: none;
  margin : 0;
  max-height: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 3.5rem;
  width: 100%;
  transition: max-height .5s ease;

  @media screen and (min-width: $breakpoint) {
    background-color: transparent;
    border: 0;
    display: flex;
    margin-left: auto;
    max-height: 100vh;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.navigation__items--active {
  max-height: 100vh;
}

.navigation__item {
  position: relative;
}

.navigation__link,
.navigation__dropdown-item {
  color: var(--body-color);
  border-bottom: 1px solid var(--color-gray-500);
  display: block;
  margin: 0 1rem;
  padding: .75rem 1rem;
  text-decoration: none;

  @media screen and (min-width: $breakpoint) {
    border: 0;
    margin: 0;
    padding: 1rem .5rem;
  }

  @media screen and (min-width: $container) {
    border: 0;
    margin: 0;
    padding: 1rem .75rem;
  }
}

.navigation__items li:last-of-type .navigation__link {
  border: 0;
}

.navigation__dropdown {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  list-style: none;
  margin: 0;
  padding: 0 1rem;

  @media screen and (min-width: $breakpoint) {
    background-color: rgba(var(--body-background-rgb), .75);
    backdrop-filter: blur(.25rem);
    display: none;
    left: -1rem;
    min-width: 15rem;
    position: absolute;
  }
}
.navigation__dropdown--active {
  display: block;
}
.navigation__dropdown-item {
  margin-left: 3rem;

  @media screen and (min-width: $breakpoint) {
    border-top: 1px solid var(--color-gray-500);
    margin: 0;
  }
}
.navigation__dropdown li:first-of-type .navigation__dropdown-item {
  border-top: 0;
}

.navigation__actions {
  display: flex;
  gap: .5rem;
  padding: .5rem 0;

  @media screen and (min-width: $breakpoint) {
    order: 2;
  }
}

.navigation__action {
  background-color: var(--color-blue);
  border-radius: var(--border-radius);
  color: var(--color-gray-100);
  padding: .25rem .5rem;
  text-decoration: none;
}
