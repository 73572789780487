*, *:before, *:after {
  box-sizing: border-box;
}

/* block elements */

body {
  background-color: var(--body-background);
  color: var(--body-color);
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 1.5rem;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0 0 1rem;
}

h1 {
  font-size: 2rem;
  line-height: 2.5rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
h2 {
  font-size: 1.875rem;
  line-height: 2.375rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
h3 {
  font-size: 1.75rem;
  line-height: 2.25rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
h4 {
  font-size: 1.625rem;
  line-height: 2.125rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}
h5 {
  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

p {
  margin: 0 0 1rem;
}

/* inline elements */

button {
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
}

input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

label[for] {
  cursor: pointer;
}
