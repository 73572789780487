/* Variable fonts usage:
:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'InterVariable', sans-serif; font-optical-sizing: auto; }
} */
@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../../../Resources/assets/fonts/InterVariable.woff2') format('woff2');
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../../../../Resources/assets/fonts/InterVariable-Italic.woff2') format('woff2');
}

/* static fonts */
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 100; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Thin.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 100; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-ThinItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 200; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-ExtraLight.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 200; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-ExtraLightItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 300; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Light.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 300; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-LightItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 400; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Regular.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 400; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Italic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 500; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Medium.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 500; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-MediumItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 600; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-SemiBold.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 600; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-SemiBoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 700; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Bold.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 700; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-BoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 800; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-ExtraBold.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 800; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-ExtraBoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: normal; font-weight: 900; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-Black.woff2') format('woff2'); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: 900; font-display: swap; src: url('../../../../Resources/assets/fonts/Inter-BlackItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 100; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Thin.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 100; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-ThinItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 200; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-ExtraLight.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 200; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-ExtraLightItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 300; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Light.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 300; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-LightItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 400; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Regular.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 400; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Italic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 500; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Medium.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 500; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-MediumItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 600; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-SemiBold.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 600; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-SemiBoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 700; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Bold.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 700; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-BoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 800; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-ExtraBold.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 800; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-ExtraBoldItalic.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: normal; font-weight: 900; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-Black.woff2') format('woff2'); }
@font-face { font-family: 'InterDisplay'; font-style: italic; font-weight: 900; font-display: swap; src: url('../../../../Resources/assets/fonts/InterDisplay-BlackItalic.woff2') format('woff2'); }

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}
@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, sans-serif; }
}

//:root {
//  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
//}
//@supports (font-variation-settings: normal) {
//  :root { font-family: InterVariable, sans-serif; }
//}
//body {
//  font-feature-settings: 'cv03', 'cv04', 'cv11';
//}
