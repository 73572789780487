:root {
  --color-blue: #209cee;
  --color-blue-rgb: 32, 156, 238;
  --color-indigo: #6610f2;
  --color-indigo-rgb: 102, 16, 242;
  --color-purple: #6f42c1;
  --color-purple-rgb: 111, 66, 193;
  --color-pink: #d63384;
  --color-pink-rgb: 214, 51, 132;
  --color-red: #dc3545;
  --color-red-rgb: 220, 53, 69;
  --color-orange: #fd7e14;
  --color-orange-rgb: 253, 126, 20;
  --color-yellow: #ffc107;
  --color-yellow-rgb: 255, 193, 7;
  --color-green: #198754;
  --color-green-rgb: 25, 135, 84;
  --color-teal: #20c997;
  --color-teal-rgb: 32, 201, 151;
  --color-cyan: #0dcaf0;
  --color-cyan-rgb: 13, 202, 240;
  --color-black: #000;
  --color-black-rgb: 0, 0, 0;
  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;
  --color-gray-100: #f8f9fa;
  --color-gray-100-rgb: 248, 249, 250;
  --color-gray-200: #e9ecef;
  --color-gray-200-rgb: 233, 236, 239;
  --color-gray-300: #dee2e6;
  --color-gray-300-rgb: 222, 226, 230;
  --color-gray-400: #ced4da;
  --color-gray-400-rgb: 206, 212, 218;
  --color-gray-500: #adb5bd;
  --color-gray-500-rgb: 173, 181, 189;
  --color-gray-600: #6c757d;
  --color-gray-600-rgb: 108, 117, 125;
  --color-gray-700: #495057;
  --color-gray-700-rgb: 73, 80, 87;
  --color-gray-800: #343a40;
  --color-gray-800-rgb: 52, 58, 64;
  --color-gray-900: #212529;
  --color-gray-900-rgb: 33, 37, 41;

  --body-background: var(--color-gray-100);
  --body-background-rgb: var(--color-gray-100-rgb);
  --body-color: var(--color-gray-700);
  --body-color-rgb: var(--color-gray-700-rgb);

  --breakpoint: $breakpoint;
  --container: $container;
  --border-radius: .5rem;

  --content-background: var(--color-white);
  --content-background-rgb: var(--color-white-rgb);

  --font-family: 'Inter', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-background: var(--color-gray-800);
    --body-background-rgb: var(--color-gray-800-rgb);
    --body-color: var(--color-gray-200);
    --body-color-rgb: var(--color-gray-200-rgb);

    --content-background: var(--color-gray-700);
    --content-background-rgb: var(--color-gray-700-rgb);
  }
}
