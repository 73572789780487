.footer {
  background-color: rgba(var(--body-background-rgb), .75);
  backdrop-filter: blur(.25rem);
  margin-top: -2rem;
  padding-top: 2rem;
}

.footer__container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  max-width: $container;
  padding: 2rem;

  @media screen and (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.footer__link {
  color: var(--body-color);
  display: block;
  text-decoration: none;
}
