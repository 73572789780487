$breakpoint: 48rem; // 768px
$container: 60rem; // 960px

// FONT AWESOME --------------------------------------------------------------------------------------------------------
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts' !default;

@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/functions';
@import 'variables/font-awesome-main';
@import 'variables/font-awesome-icons';
//@import '@fortawesome/fontawesome-free/scss/variables';
@import '@fortawesome/fontawesome-free/scss/mixins';
@import '@fortawesome/fontawesome-free/scss/core';
//@import '@fortawesome/fontawesome-free/scss/sizing';
@import '@fortawesome/fontawesome-free/scss/fixed-width';
//@import '@fortawesome/fontawesome-free/scss/list';
//@import '@fortawesome/fontawesome-free/scss/bordered-pulled';
//@import '@fortawesome/fontawesome-free/scss/animated';
@import '@fortawesome/fontawesome-free/scss/rotated-flipped';
//@import '@fortawesome/fontawesome-free/scss/stacked';
@import '@fortawesome/fontawesome-free/scss/icons';
//@import '@fortawesome/fontawesome-free/scss/screen-reader';

// RESET / VARIABLES / FONTS -------------------------------------------------------------------------------------------

@import 'variables/variables.scss';
@import 'layout/reset.scss';
@import '../../../../Resources/assets/styles/fonts.scss';
@import 'layout/navigation.scss';
@import 'layout/footer.scss';

// MAIN

body {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url('../images/background.jpg');
  background-position: top center;
  background-size: cover;
}

main {
  position: relative;
  z-index: 1;

  @media screen and (min-width: $breakpoint) {
    min-height: calc(100vh - 32rem);
  }
}

.homepage {
  margin: 20rem auto 0;
  max-width: $container;
  min-height: calc(100vh - 24.5rem);
  width: calc(100vw - 4rem);

  @media screen and (min-width: $breakpoint) {
    min-height: calc(100vh - 32rem);
  }
}
.homepage__title {
  font-size: 1.625rem;

  @media screen and (min-width: $breakpoint) {
    font-size: 2.25rem;
  }
}
.homepage__title mark {
  background-color: transparent;
  background: linear-gradient(90deg, var(--color-blue) 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 200% 100%;
  background-position: 0 0;
  color: var(--color-gray-100);
  display: inline-block;
  padding: .125rem .5rem;
}

.page {
  background-color: var(--body-background);
  border-radius: var(--border-radius);
  margin: 20rem auto 0;
  max-width: $container;
  padding: 2rem;
  width: calc(100vw - 4rem);
}
.page__title {
  margin-bottom: 2rem;
}
